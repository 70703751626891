import { Injectable, inject } from '@angular/core';
import { type Observable } from 'rxjs';

import { type CompanyContact } from './models/contact';
import { type Address } from '../core/models/address';
import { type Company, type ConnectionStatus } from '../core/models/company';
import { HttpService } from '../core/services/http.service';

export interface CompanySearchData {
  name?: string;
  website?: string;
  address?: Address;
  connectionStatusIds?: ConnectionStatus[] | undefined;
}
@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private readonly http = inject(HttpService);

  public getCompany(id: number): Observable<Company> {
    return this.http.getV2(`api/companies/${id}`);
  }

  public getOpenInvitations(count: number): Observable<any> {
    return this.http.getV2(`api/companies/my/invitations/received/search?Status=Open&Limit=${count}`);
  }

  public acceptInvitation(data: { invitationId: number }): Observable<any> {
    return this.http.postV2('api/companies/my/invitations/accept', data);
  }

  public declineInvitation(data: { invitationId: number; comment: string }): Observable<any> {
    return this.http.postV2('api/companies/my/invitations/decline', data);
  }

  public sendInvitation(companyId: number): Observable<any> {
    return this.http.postV2('api/companies/my/invitations', { companyId });
  }

  public searchCompanies(data: CompanySearchData, offset: number, limit: number): Observable<any> {
    return this.http.postV2(`api/search/companies?offset=${offset}&limit=${limit}`, data);
  }

  public searchContacts(data: any, offset: number, limit: number): Observable<any> {
    return this.http.postV2(`api/search/contacts?offset=${offset}&limit=${limit}`, data);
  }

  // public searchContactsForCompany(companyId: number): Observable<any> {
  //   return this.http.postV2(`api/companies/my/contacts/${companyId}/search`);
  // }

  public searchCompanyContacts(searchParam?: any): Observable<any> {
    return this.http.getV2(`api/companies/my/contacts/search`, null, searchParam);
  }

  public createCompanyByUser(data: any): Observable<Company> {
    throw new Error('Obsolete method. Remove it.');
  }

  public createDeferredCompany(data: any): Observable<Company> {
    return this.http.postV2(`/api/companies/my/deferredAliases`, data);
  }

  public sendUserInvitation(email: string): Observable<any> {
    return this.http.postV2(`api/companies/my/users/invitations`, { email });
  }

  public getProprietaryFiles(masterCompanyId: number, sharedCompanyId: number): Observable<any> {
    return this.http.getV2(`api/companies/${masterCompanyId}/connectedCompanies/${sharedCompanyId}/files`);
  }

  public createContact(data: any): Observable<CompanyContact> {
    return this.http.postV2(`api/companies/my/contacts`, data);
  }

  public updateCompanyProfile(id: number, data: any): Observable<any> {
    return this.http.putV2(`api/companies/${id}`, data);
  }

  public updateCompanyFields(id: number, data: Record<string, any>): Observable<any> {
    return this.http.putV2(`api/companies/${id}/fields`, {
      companyFieldValues: data,
    });
  }

  public getCompanyLocations(companyId: number): Observable<any> {
    return this.http.getV2(`api/companies/${companyId}/locations`);
  }

  public createLocation(data: Record<string, any>, companyId: number): Observable<any> {
    return this.http.putV2(`api/companies/${companyId}/locations`, data);
  }

  public updateLocation(id: number, companyId: number, data: Record<string, any>): Observable<any> {
    return this.http.postV2(`api/companies/${companyId}/locations/${id}`, data);
  }

  public deleteLocation(companyId: number, id: number): Observable<any> {
    return this.http.deleteV2(`api/companies/${companyId}/locations/${id}`);
  }

  public deleteLocationAndReassignContacts(companyId: number, id: number, migrateToId: number): Observable<any> {
    return this.http.deleteV2(`api/companies/${companyId}/locations/${id}?MigrateContactsToLocationId=${migrateToId}`);
  }

  public updateCompanyLogo(companyId: number, logoData: any): Observable<any> {
    return this.http.putV2(`api/companies/${companyId}/logo`, { logo: logoData });
  }

  public getCustomInfoFields(companyId: number, sharedCompanyId: number): Observable<any> {
    return this.http.getV2(`api/companies/${companyId}/connectedCompanies/${sharedCompanyId}/proprietaryFields`);
  }

  public getConnectedCompanies(): Observable<any> {
    return this.http.postV2(`api/globalSearch/companies?offset=${0}&limit=${1000}`, {
      connectionStatusIds: ['Connected'],
    });
  }

  public addUserToFavorites(userId: number): Observable<any> {
    return this.http.postV2(`api/users/my/favoriteUsers/${userId}`, {});
  }

  public removeUserFromFavorites(userId: number): Observable<any> {
    return this.http.deleteV2(`api/users/my/favoriteUsers/${userId}`);
  }

  public getCustomFieldsSchema(): Observable<any> {
    return this.http.getV2(`api/companies/fields/schema`);
  }

  public updateInternalFields(masterCompanyId: number, companyId: number, data): Observable<any> {
    return this.http.putV2(`api/companies/${masterCompanyId}/connectedCompanies/${companyId}/proprietaryFields`, {
      companyFieldValues: data,
    });
  }

  public updateConfidentialFields(companyId: number, data): Observable<any> {
    return this.http.putV2(`api/companies/${companyId}/confidentialFields`, {
      companyFieldValues: data,
    });
  }

  public getCompanyDetails(id: number): Observable<Company> {
    return this.http.getV2(`api/companies/${id}/public`);
  }

  public getDeferredCompanyDetails(id: number): Observable<Company> {
    return this.http.getV2(`api/companies/my/deferredAliases/${id}`);
  }

  public getBulkCompanyContacts(companyIds: number[]): Observable<any> {
    return this.http.postV2(`api/companies/my/contacts/connected`, {
      companyIds,
    });
  }

  public getBulkDeferredAliases(ids: number[]): Observable<any> {
    return this.http.postV2(`api/companies/my/deferredAliases/bulk`, { ids });
  }

  public getFavoriteContacts(companyIds: number[]): Observable<Record<number, any[]>> {
    let url = `api/users/my/favoriteUsers/companies`;
    if (companyIds?.length > 0) {
      url += `?companiesIds=${companyIds.map((id) => `${id}`).reduce((p, c) => `${p}&companiesIds=${c}`)}`;
    }
    return this.http.getV2(url);
  }
}
