import { type WorkflowType } from '@clover/workflows/models/workflow';
import { type UserInfo } from '@core/models/user';

import { type TaskStepTypeId } from '../constants/task-step-type-ids';

export interface TaskSearchModel {
  id: number;
  assignedAt?: string;
  assignedByUser?: UserInfo;
  acceptedByUser?: UserInfo;
  assignedToCompany?: {
    id: number;
    name: string;
    logoUrl: string;
  };
  assignedByCompany?: any;
  assignedByCompanyProduct?: {
    id: number;
    name: string;
    logoUrl: string;
    internalItemNumber: string;
    globalTradeIdentificationNumber: string;
    manufactureProductCode: string;
    itemNumber: string;
  };
  assignedTo?: {
    communicationRoles: string[];
    users: UserInfo[];
  };
  completion: number;
  name?: string;
  status?: TaskStatus;
  isAccepted: boolean;
  acceptedByUserId?: number;
}

export interface Task {
  id: number;
  name?: string;
  status?: TaskStatus;
  workflow: {
    name: string;
    description: string;
    type: WorkflowType;
    workflowId: number;
    workflowSnapshotId: number;
  };
  assignedByUser: UserInfo;
  assignedByCompany?: {
    id: number;
    name: string;
  };
  assignedByCompanyProduct?: {
    id: number;
    itemNumber: string;
    name: string;
    logoUrl: string;
    globalTradeIdentificationNumber: string;
    manufactureProductCode: string;
  };
  currentStep: TaskStep;
  completion: {
    visibleSteps: TaskStepSearchModel[];
    hasAdditionalSteps: boolean;
    numberOfCompletedSteps: number;
  };
  latestApprovalRequestResult?: TaskApprovalRequestResult;
  isAcceptedByCurrentUser?: boolean;
}

export interface TaskStep {
  instanceStepId: number;
  workflowStepTypeId: TaskStepTypeId;
  data: Record<string, any>;
  metadata: Record<string, any>;
  definition: {
    name?: string;
    description?: string;
    [x: string]: any;
  };
}

export interface TaskStepSearchModel {
  name: string;
  description: string;
  stepTypeId: TaskStepTypeId;
}

export interface TaskApprovalRequestResult {
  status: TaskApprovalResultStatus;
  comment: string | undefined;
  approver: UserInfo | undefined;
}

export enum TaskApprovalResultStatus {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Pending = 'Pending',
}

export enum TaskStatus {
  Completed = 'Completed',
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  Closed = 'Closed',
  Deferred = 'Deferred',
  WaitingForConnection = 'WaitingForConnection',
}
